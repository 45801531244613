import React, {
  Fragment,
  FunctionComponent,
  ReactEventHandler,
  ReactNode,
  SVGProps,
  useEffect,
  useReducer,
} from "react";
import { Popover, Transition } from "@headlessui/react";
import Head from "next/head";
import Link from "next/link";
import Image, { StaticImageData } from "next/legacy/image";

import logo from "../public/images/logo-large.png";
import {
  Bars3Icon as MenuIcon,
  CheckCircleIcon,
  ExclamationTriangleIcon,
  XMarkIcon as XIcon,
} from "@heroicons/react/24/outline";
import Spinner from "./Spinner";

type PageProps = {
  title?: string;
  description?: string;
  children: ReactNode;
};

type IconProps = SVGProps<SVGSVGElement>;

interface FormElements extends HTMLFormControlsCollection {
  email: HTMLInputElement;
}

interface NewsletterFormElement extends HTMLFormElement {
  readonly elements: FormElements;
}

const navigation = [
  {
    name: "Instagram",
    href: "http://instagram.com/thecakedetective",
    icon: (props: IconProps) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path
          fillRule="evenodd"
          d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
  {
    name: "Facebook",
    href: "http://facebook.com/thecakedetective",
    icon: (props: IconProps) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path
          fillRule="evenodd"
          d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
  // {
  //   name: "Twitter",
  //   href: "#",
  //   icon: (props: IconProps) => (
  //     <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
  //       <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
  //     </svg>
  //   ),
  // },
  {
    name: "YouTube",
    href: "https://www.youtube.com/watch?v=SglS8KmQu6k",
    icon: (props: IconProps) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path
          fillRule="evenodd"
          d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
];

const HeaderCompact: FunctionComponent = () => {
  return (
    <Popover className="relative bg-brand-navy">
      <header className="z-10 w-full bg-brand-navy/50 bg-gradient-to-b from-brand-navy/100 to-brand-navy/90 border-t-4 border-brand-ranga">
        <nav
          className="container mx-auto px-4 sm:px-6 lg:px-8 "
          aria-label="Top"
        >
          <div className="w-full py-4 flex items-center justify-between">
            <div className="flex items-center">
              <Link
                href="/"
                className="w-12 h-12 md:h-16 md:w-16 lg:h-24 lg:w-24"
                passHref
              >
                <span>
                  <span className="sr-only">The Cake Detective</span>
                  <Image
                    src={logo}
                    layout="intrinsic"
                    width="96"
                    height="96"
                    alt="The Cake Detective"
                  />
                </span>
              </Link>
            </div>
            <div className="hidden ml-10 space-x-4 lg:flex lg:items-center lg:gap-4">
              <Link
                href="/"
                className="hidden lg:inline text-sm uppercase tracking-wide font-semibold text-brand-pink hover:text-brand-navy-50"
              >
                Home
              </Link>
              <Link
                href="/support-us"
                className="hidden lg:inline text-sm uppercase tracking-wide font-semibold text-brand-pink hover:text-brand-navy-50"
              >
                Support Us
              </Link>
              <Link
                href="/recipients"
                className="hidden lg:inline text-sm uppercase tracking-wider font-bold text-brand-pink hover:text-brand-navy-50"
              >
                Recipients
              </Link>
              <Link
                href="/contact-us"
                className="hidden lg:inline text-sm uppercase tracking-wider font-bold text-brand-pink hover:text-brand-navy-50"
              >
                Contact Us
              </Link>
              <Link
                href="/cake-request-form"
                className="inline-block bg-transparent py-2 px-4 border border-brand-teal rounded-md text-base text-brand-teal font-bold hover:bg-opacity-75"
              >
                Request A Cake
              </Link>
              <Link
                href="/support-us#donate"
                className="inline-block bg-brand-pink py-2 px-4 border border-transparent rounded-md text-base font-bold text-teal-50 hover:bg-brand-ranga-50"
              >
                Donate
              </Link>
            </div>
            <div className="lg:hidden">
              <Popover.Button className="bg-brand-navy rounded-md p-2 inline-flex items-center justify-center text-brand-pink hover:text-brand-teal focus:outline-none focus:ring-2 focus:ring-inset focus:ring-brand-teal">
                <span className="sr-only">Open menu</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
          </div>
        </nav>
      </header>
      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute top-0 inset-x-0 p-3 transition transform origin-top-right lg:hidden z-10"
        >
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-cream divide-y-2 divide-brand-ranga">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <span className="sr-only">The Cake Detective</span>
                <Link
                  href="/"
                  passHref
                  className="w-12 h-12 md:h-16 md:w-16 lg:h-24 lg:w-24"
                >
                  <Image
                    src={logo}
                    layout="intrinsic"
                    width="96"
                    height="96"
                    alt="The Cake Detective"
                  />
                </Link>
                <div className="">
                  <Popover.Button className="bg-brand-ranga/25 rounded-md p-2 inline-flex items-center justify-center text-brand-navy/75 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-brand-pink">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-y-4">
                  <Link
                    href="/"
                    className="text-sm uppercase tracking-wide font-semibold text-brand-ranga hover:text-brand-navy-50"
                  >
                    Home
                  </Link>
                  <Link
                    href="/support-us"
                    className="text-sm uppercase tracking-wide font-semibold text-brand-ranga hover:text-brand-navy-50"
                  >
                    Support Us
                  </Link>
                  <Link
                    href="/recipients"
                    className="text-sm uppercase tracking-wider font-bold text-brand-ranga hover:text-brand-navy-50"
                  >
                    Recipients
                  </Link>
                  <Link
                    href="/contact-us"
                    className="text-sm uppercase tracking-wide font-semibold text-brand-ranga hover:text-brand-navy-50"
                  >
                    Contact Us
                  </Link>
                </nav>
              </div>
            </div>
            <div className="py-6 px-5 space-y-6 bg-brand-navy rounded-b-lg">
              <div>
                <Link
                  href="/support-us#donate"
                  className="inline-block bg-brand-pink py-2 px-4 border w-full text-center border-transparent rounded-md text-base font-bold text-white hover:bg-brand-ranga-50"
                >
                  Donate Today
                </Link>
                <Link
                  href="/cake-request-form"
                  className="mt-6 inline-block bg-transparent py-2 px-4 w-full text-center border border-brand-teal rounded-md text-base text-brand-teal font-bold hover:bg-opacity-75"
                >
                  Cake Request Form
                </Link>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

const Content: FunctionComponent = ({ children }) => {
  return (
    <main className="container mx-auto">
      <div className="mx-4 sm:mx-6 md:mx-0">{children}</div>
    </main>
  );
};

type SubscribeStatus = "ready" | "submitting" | "done" | "error";
type SubscribeState = {
  status: SubscribeStatus;
  email?: string;
};

enum SubscribeActionType {
  SET_EMAIL,
  SET_DONE,
  RESET,
  ERROR,
}

type SubscribeAction =
  | {
      type: SubscribeActionType.SET_EMAIL;
      email: string;
    }
  | {
      type: SubscribeActionType.SET_DONE;
    }
  | {
      type: SubscribeActionType.RESET;
    }
  | {
      type: SubscribeActionType.ERROR;
    };

const subscribeReducer = (
  state: SubscribeState,
  action: SubscribeAction
): SubscribeState => {
  switch (action.type) {
    case SubscribeActionType.SET_EMAIL:
      return {
        status: "submitting",
        email: action.email,
      };
    case SubscribeActionType.SET_DONE:
      return {
        status: "done",
        email: "",
      };
    case SubscribeActionType.RESET:
      return {
        status: "ready",
      };
    case SubscribeActionType.ERROR:
      return {
        status: "error",
      };
    default:
      return state;
  }
};

const useSubscribeForm = (): [
  SubscribeStatus,
  ReactEventHandler<NewsletterFormElement>
] => {
  const [state, dispatch] = useReducer(subscribeReducer, { status: "ready" });

  const onSubmit: ReactEventHandler<NewsletterFormElement> = (e) => {
    e.preventDefault();
    const target = e.target as HTMLFormElement;
    const email = (target.elements[0] as HTMLInputElement).value;
    const parts = email.split("@");
    // very basic email test: has '@' and last bit after '@' has a '.'
    if (parts.length >= 2 && parts.at(-1)?.includes(".")) {
      dispatch({
        type: SubscribeActionType.SET_EMAIL,
        email,
      });
    }
  };

  useEffect(() => {
    if (state.status === "submitting") {
      const fetchData = async () => {
        try {
          await fetch("/api/subscribe", {
            method: "post",
            body: JSON.stringify({ email: state.email }),
            headers: {
              "Content-Type": "application/json",
            },
          });
          dispatch({ type: SubscribeActionType.SET_DONE });
        } catch (error) {
          console.log(error);
          dispatch({ type: SubscribeActionType.ERROR });
        }
      };
      fetchData().catch((error) => {
        console.log(error);
        dispatch({ type: SubscribeActionType.ERROR });
      });
    }
  }, [state.status, state.email]);

  return [state.status, onSubmit];
};

const Footer: FunctionComponent = ({ children }) => {
  const year = new Date().getFullYear();
  const [formState, onSubmit] = useSubscribeForm();

  return (
    <footer className="bg-navy-base text-white border-t-4 border-ranga">
      <div className="mx-auto container px-6 pb-8 lg:px-8 ">
        <div className="pt-8 lg:flex lg:items-center lg:justify-between">
          <div>
            <h3 className="text-sm font-semibold leading-6 text-brand-ranga">
              Subscribe to our newsletter
            </h3>
            <p className="mt-2 text-sm leading-6 text-brand-ranga/75">
              Sign up with your email address to receive news and updates.
            </p>
          </div>
          {formState === "ready" ? (
            <form
              className="mt-6 sm:flex sm:max-w-md lg:mt-0"
              onSubmit={onSubmit}
            >
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                type="email"
                name="email-address"
                id="email-address"
                autoComplete="email"
                required
                className="w-full min-w-0 appearance-none rounded-md border-brand-ranga/25 bg-white/25 px-[calc(theme(spacing.3)-1px)] py-[calc(theme(spacing[1.5])-1px)] text-base leading-7 text-brand-navy placeholder-brand-teal/50 shadow-sm focus:placeholder-brand-navy/75 focus:border-brand-pink focus:bg-white focus:ring-brand-pink sm:w-56 sm:text-sm sm:leading-6"
                placeholder="Enter email to subscribe"
              />
              <div className="mt-4 rounded-md sm:mt-0 sm:ml-4 sm:flex-shrink-0">
                <button
                  type="submit"
                  className="flex w-full items-center justify-center rounded-md bg-brand-pink py-1.5 px-3 text-base font-semibold leading-7 text-white hover:bg-brand-pink/90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-pink sm:text-sm sm:leading-6"
                >
                  Subscribe
                </button>
              </div>
            </form>
          ) : (
            <div className="mt-6 sm:flex sm:max-w-md lg:mt-0">
              {formState === "submitting" && <Spinner />}
              {formState === "error" && (
                <span className=" text-sm text-brand-teal flex items-center justify-start sm:justify-end gap-2">
                  <ExclamationTriangleIcon
                    className="h-6 w-6"
                    aria-hidden="true"
                  />
                  <span>
                    Sorry, something went wrong. Please{" "}
                    <a
                      href="mailto:info@thecakedetective.com"
                      className="text-brand-pink underline"
                    >
                      email us
                    </a>{" "}
                    to subscribe.
                  </span>
                </span>
              )}
              {formState === "done" && (
                <span className=" text-sm text-brand-teal flex items-center justify-start sm:justify-end gap-2">
                  <CheckCircleIcon className="h-6 w-6" aria-hidden="true" />
                  Thanks! Please confirm the subscription in your email
                </span>
              )}
            </div>
          )}
        </div>
        <div className="mt-8 border-t border-brand-ranga/25 pt-8 md:flex md:items-center md:justify-between">
          <div className="flex justify-center space-x-6 md:order-2">
            {navigation.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="text-gray-400 hover:text-brand-pink"
              >
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </a>
            ))}
          </div>
          <div className="mt-8 md:order-1 md:mt-0">
            <p className="text-center text-xs leading-5 text-gray-500">
              &copy; {year} The Cake Detective Charitable Trust &bull;
              registered under the Charities Act 2005 CC57514
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

const Page: FunctionComponent<PageProps> & {
  Header: typeof HeaderCompact;
  Content: typeof Content;
  Footer: typeof Footer;
} = ({
  title = "The Cake Detective | Every Child Deserves Cake",
  description = "All children should have access to the gift of a birthday cake made with love just for them. The Cake Detective is here to close the case on missing birthday cakes in the Waikato.",
  children,
}) => {
  return (
    <div className="bg-cream bg-cake-pattern">
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:site_name" content="The Cake Detective" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="https://www.thecakedetective.org.nz" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content={description} />
        <meta
          property="og:image"
          content="http://static1.squarespace.com/static/5da3f7da0e31e4217191e89d/t/61381757d2ad654459c26db3/1631065943566/TCD+logo+colour+large.jpg?format=1500w"
        />
        <meta property="og:image:width" content="1500" />
        <meta property="og:image:height" content="1500" />
        <meta itemProp="name" content="The Cake Detective" />
        <meta itemProp="url" content="https://www.thecakedetective.org.nz" />
        <meta
          itemProp="description"
          content="Birthday Cakes   Every child deserves cake"
        />
        <meta
          itemProp="thumbnailUrl"
          content="http://static1.squarespace.com/static/5da3f7da0e31e4217191e89d/t/61381757d2ad654459c26db3/1631065943566/TCD+logo+colour+large.jpg?format=1500w"
        />
        <link
          rel="image_src"
          href="http://static1.squarespace.com/static/5da3f7da0e31e4217191e89d/t/61381757d2ad654459c26db3/1631065943566/TCD+logo+colour+large.jpg?format=1500w"
        />
        <meta
          itemProp="image"
          content="http://static1.squarespace.com/static/5da3f7da0e31e4217191e89d/t/61381757d2ad654459c26db3/1631065943566/TCD+logo+colour+large.jpg?format=1500w"
        />
        <meta name="twitter:title" content="The Cake Detective" />
        <meta
          name="twitter:image"
          content="http://static1.squarespace.com/static/5da3f7da0e31e4217191e89d/t/61381757d2ad654459c26db3/1631065943566/TCD+logo+colour+large.jpg?format=1500w"
        />
        <meta
          name="twitter:url"
          content="https://www.thecakedetective.org.nz"
        />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="Birthday Cakes   Every child deserves cake"
        />
        <meta name="description" content="" />

        <link rel="icon" href="/favicon/favicon.ico" />
      </Head>
      {children}
    </div>
  );
};

Page.Header = HeaderCompact;
Page.Content = Content;
Page.Footer = Footer;

export default Page;
