import React, { FunctionComponent } from "react";
import Image from "next/legacy/image";

import spp from "../public/logos/sweetpeas.png";
import basecorp from "../public/logos/sponsor-basecorp.png";
import anchor from "../public/images/anchor.png";
import orb from "../public/logos/orb-clean.png";
import gobake from "../public/logos/go-bake.png";

const Thanks: FunctionComponent<{ title?: string }> = ({
  title = "Made possible by our generous sponsors",
}) => {
  return (
    <div className="bg-white/25 rounded-xl my-4 md:my-12">
      <div className="py-8 px-4 sm:px-8 lg:px-12">
        <div className="flex flex-col items-center gap-8">
          <div className="w-full">
            <h2 className="max-w-md mx-auto text-xl align-baseline font-extrabold text-brand-navy text-center lg:max-w-xl">
              {title}
            </h2>
          </div>
          <div className="flex flex-wrap gap-y-8 items-center justify-center w-full py-4 sm:py-0">
            <div className="w-1/2 md:w-1/5 px-8">
              <a href="https://www.sweetpeaparties.co.nz/">
                <Image src={spp} alt="Sweetpea Parties" layout="responsive" />
              </a>
            </div>
            <div className="w-1/2 md:w-1/5 px-8">
              <a href="https://www.anchordairy.com/">
                <Image src={anchor} alt="Anchor Dairy" layout="responsive" />
              </a>
            </div>
            <div className="w-1/2 md:w-1/5 px-10 order-last md:order-none">
              <a href="https://gobake.co.nz/">
                <Image
                  src={gobake}
                  alt="Go Bake"
                  layout="responsive"
                  className="object-fill"
                />
              </a>
            </div>
            <div className="w-1/2 md:w-1/5 px-8">
              <a href="https://orbpro.co.nz/">
                <Image src={orb} alt="Orb Property" layout="responsive" />
              </a>
            </div>
            <div className="w-1/2 md:w-1/5 px-8">
              <a href="https://basecorp.co.nz">
                <Image
                  src={basecorp}
                  alt="Basecorp Finance - Hamilton"
                  layout="responsive"
                  className="object-fill"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Thanks;
